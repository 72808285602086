<template>
    <div id="file-importer" class="overflow-hidden pt-20 pb-44">
        <div class="mx-auto max-w-7xl md:px-6 lg:px-8">
            <div class="grid grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:grid-cols-2 lg:items-start">
                <div class="px-6 lg:px-0 lg:pr-4 lg:pt-4">
                    <div class="mx-auto max-w-2xl lg:mx-0 lg:max-w-lg">
                        <div>
                            <div class="inline-flex font-medium bg-clip-text text-transparent bg-gradient-to-r from-emerald-500 to-emerald-200 pb-3">File Importer</div>
                        </div>
                        <h2 class="h2 bg-clip-text text-transparent bg-gradient-to-r from-zinc-200/60 via-zinc-200 to-zinc-200/60 pb-4">Convert & transfer CSV files.</h2>
                        <p class="mt-6 text-lg leading-8 text-zinc-500">If your bank isn't supported, or you'd simply like an easier way to import CSV files to YNAB, we have the tool for you. And the best part? It's free.</p>
                        <dl class="mt-10 max-w-xl space-y-8 text-base leading-7 text-zinc-400 lg:max-w-none">
                            <div v-for="feature in features2" :key="feature.name" class="relative pl-9">
                                <dt class="inline font-semibold text-zinc-100">
                                    <component :is="feature.icon" class="absolute left-1 top-1 h-5 w-5 text-emerald-500" aria-hidden="true" />
                                    {{ feature.name }}
                                </dt>
                                {{ ' ' }}
                                <dd class="inline">{{ feature.description }}</dd>
                            </div>
                        </dl>
                    </div>
                </div>
                <div class="px-6 lg:px-0 mt-5 h-full">
                    <div class="mx-auto items-center justify-center flex h-full max-w-2xl sm:mx-0 sm:max-w-none">
                        <div @click="router.visit(route('import'))" type="button" class="flex items-center justify-center cursor-pointer w-full h-full rounded-3xl border-2 border-dashed border-zinc-700 p-12 text-center hover:border-zinc-600 hover:bg-zinc-700/5 focus:outline-none duration-150">
                            <div class="relative">
                                <svg class="mx-auto h-12 w-12 text-zinc-300 text-opacity-60" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                    <ArrowUpTrayIcon class="text-white/80 flex-shrink-0 h-6 w-6" />
                                </svg>
                                <p class="mt-5 block text-sm font-semibold text-zinc-100">Upload bank file</p>
                                <p class="mt-1 text-sm text-zinc-400">Click here to use the file importer</p>


                                <SecondaryButton force-dark-mode round class="mt-5">
                                    <ChevronDownIcon class="h-3.5 w-3.5 mr-2" aria-hidden="true" />
                                    Settings
                                </SecondaryButton>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {
    ArrowUpTrayIcon,
    BookmarkIcon,
    ChevronDownIcon,
    PaperAirplaneIcon,
    ViewColumnsIcon
} from '@heroicons/vue/24/outline'
import SecondaryButton from "../../Components/SecondaryButton.vue";
import {router} from "@inertiajs/vue3";

const features2 = [
    {
        name: 'Map columns.',
        description: 'The file importer accepts any CSV file, regardless of its column format. Easily map columns to the correct YNAB fields after upload.',
        icon: ViewColumnsIcon,
    },
    {
        name: 'Save your configuration.',
        description: 'Create a free Synci account to save your column configuration for later use.',
        icon: BookmarkIcon,
    },
    {
        name: 'Transfer directly to YNAB.',
        description: 'If you have Synci account, you can transfer your CSV file directly to YNAB for free. If you don\'t, you can download a converted file that\'s ready for YNAB.',
        icon: PaperAirplaneIcon,
    },
];
</script>
